<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Diagnosticos</h3>
        </div>
        <div class="card-body">
          <b-table
            :fields="['nome', 'descricao', 'status', 'acoes']"
            :items="lista_diagnosticos"
            :per-page="perPage"
            :current-page="currentPage"
            id="local-table"
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #cell(status)="{ item }">
              <div class="text-right w-100">
                <span
                  v-if="item.status == 1"
                  class="label label-lg label-inline label-light-success"
                  >ativo</span
                >
                <span
                  v-if="item.status == 0"
                  class="label label-lg label-inline label-light-warning"
                  >inativo</span
                >
              </div>
            </template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button
                  v-show="lista_permissoes_filial.u_Local"
                  @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>
                <button
                  v-show="lista_permissoes_filial.lock_Local"
                  @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_diagnosticos.length"
            :per-page="perPage"
            aria-controls="local-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Diagnóstico" }]);
  },
  created() {
    this.listar_diagnosticos();
  },
  computed: {
    lista_diagnosticos() {
      return this.$store.state.configEmpresa.lista_diagnosticos;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
  },
  methods: {
    async listar_diagnosticos() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("configEmpresa/listar_diagnosticos")
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("configEmpresa/atualizar", "");
        });
    },
    atualizar(value) {
      this.$router.push({ name: "createDiagnostico" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara este Local no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("configEmpresa/bloquear_diagnostico", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>
  
  <style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>